import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { RadioGroupModel } from '@models/radio-group.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { HeroControllerService } from '@services/onchain/hero-controller.service';
import { ItemControllerService } from '@services/onchain/item-controller.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { InventoryActions } from '@shared/actions/inventory.actions';
import { LoadingActions } from '@shared/actions/loading.actions';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { DROPDOWN_SIZE } from '@shared/components/dropdown/constants/dropdown-sizes.constant';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { FILTER_TYPE, IFilter } from '@shared/components/filters-dialog/interfaces/filters.interface';
import { ItemsFiltersDialogComponent } from '@shared/components/filters-dialog/items-filter/items-filters-dialog.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { RadioGroupComponent } from '@shared/components/radio-group/radio-group.component';
import { SearchComponent } from '@shared/components/search/search.component';
import { ATTRIBUTES } from '@shared/constants/attributes.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { getItemNameBySymbol } from '@shared/constants/game.constant';
import { ItemActionType } from '@shared/constants/inventory.constants';
import {
  ITEM_SLOT,
  ITEM_TYPE,
  ITEM_TYPE_NAMES,
  ITEM_TYPE_TO_ITEM_SLOTS_MAPPING,
} from '@shared/constants/items.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { countItems, keysOfEnum, rangeArray } from '@shared/utils';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, distinctUntilChanged, finalize, forkJoin, takeUntil } from 'rxjs';

import { InventoryAllComponent } from '../inventory-all/inventory-all.component';
import { InventoryEquippedComponent } from '../inventory-equipped/inventory-equipped.component';

import { INVENTORY_VIEW } from './inventory-view';
import { ReinforcementService } from '@services/onchain/reinforcement.service';

export enum VIEW_MODE {
  ICON,
  LIST,
}

@Component({
  standalone: true,
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    LoadingSmallComponent,
    DialogTitleComponent,
    RadioGroupComponent,
    DropdownComponent,
    SearchComponent,
    TranslateModule,
    InventoryEquippedComponent,
    InventoryAllComponent,
  ],
})
export class InventoryComponent implements OnInit {
  // common
  account: string;
  chainId: number;
  loading = true;

  // hero
  heroAdr: string;
  heroId: number;
  hero: HeroEntity;
  isStaked = false;

  items: ItemEntityExtendedType[] = [];
  availableItemsBySlots = new Map<ITEM_SLOT, ItemEntity[]>();
  equippedItemBySlots = new Map<ITEM_SLOT, ItemEntity>();

  equipWaiting = false;
  useWaiting = false;
  INVENTORY_VIEW = INVENTORY_VIEW;
  currentInventoryView = INVENTORY_VIEW.EQUIPPED;

  currentFilterItemClass = -1;

  itemSlotsArray = keysOfEnum(ITEM_SLOT);
  DROPDOWN_SIZE = DROPDOWN_SIZE;

  inventoryTypes: RadioGroupModel[] = [
    {
      id: INVENTORY_VIEW.ALL,
      label: 'inventory.types.all',
    },
    {
      id: INVENTORY_VIEW.EQUIPPED,
      label: 'inventory.types.equipped',
    },
  ];

  itemsTypeNamesOptions: DropdownItemModel[] = [...ITEM_TYPE_NAMES].map(([value, name]) => {
    return new DropdownItemModel({
      label: this.translateService.instant(name),
      id: value.toString(),
    });
  });
  dropdownTypeNamesSelected: DropdownItemModel;

  searchControl: FormControl<string | null> = new FormControl<string | null>(null);
  currentFilterQuery: { query: string | null } = { query: null };

  itemsSortOptions: DropdownItemModel[] = [
    {
      label: this.translateService.instant('inventory.sort.default'),
      id: '',
    },
    {
      label: this.translateService.instant('inventory.sort.rarity'),
      id: 'rarity',
    },
    {
      label: this.translateService.instant('inventory.sort.level'),
      id: 'level',
    },
  ];
  dropdownSortSelected = this.itemsSortOptions[0];
  viewMode: { query: VIEW_MODE } = { query: VIEW_MODE.ICON };
  currentItemsSort: { query: string };
  filters?: IFilter[] = [];
  generalFilters?: { [key: string]: { min: string; max: string } };
  onlyConcreteItemType = -1;
  onlyConcreteHeroes = 0;
  filteredItems: ItemEntityExtendedType[] = [];

  private isNeedToUpdateHero = false;
  private heroToken: string;
  private heroTokenId: number;
  private filtersDialogRef:
    | DialogRef<
        { filters: IFilter[]; general: { [key: string]: { min: string; max: string } } },
        ItemsFiltersDialogComponent
      >
    | undefined;

  constructor(
    @Inject(DIALOG_DATA) public data: { heroToken: string; heroId: number },
    private destroy$: DestroyService,
    private tokenService: TokenService,
    private itemControllerService: ItemControllerService,
    private heroControllerService: HeroControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private providerService: ProviderService,
    private appStateService: AppStateService,
    private subgraphService: SubgraphService,
    private reinforcementService: ReinforcementService,
    private router: Router,
    private translateService: TranslateService,
    private dialog: Dialog,
    private logger: NGXLogger,
    private mediator: Mediator,
    private dialogRef: DialogRef<boolean, InventoryComponent>,
  ) {
    this.heroAdr = this.data.heroToken;
    this.heroId = this.data.heroId;

    this.appStateService.setHeaderState({
      isInventoryOpen: true,
      isAccountBalanceShown: true,
      backUrl: [MAIN_ROUTES.HERO, this.heroAdr, this.heroId.toString()],
    });

    this.resetInventorySlots();
  }

  ngOnInit(): void {
    this.heroAdr = this.data.heroToken;
    this.heroId = this.data.heroId;

    this.itemsTypeNamesOptions = [
      new DropdownItemModel({
        label: 'All',
        id: '-1',
      }),
      ...this.itemsTypeNamesOptions,
    ];

    this.dropdownTypeNamesSelected = this.itemsTypeNamesOptions[0];

    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.heroControllerService.adjustFees();
        this.itemControllerService.adjustFees();
        this.tokenService.adjustFees();
        this.init();
      },
    );

    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(query => {
        this.currentFilterQuery = { query };

        this.getPositionsPerPageWithSortAndFilter();

        this.changeDetectorRef.detectChanges();
      });

    this.updateTabParam(this.currentInventoryView);

    this.mediator.ofAction(InventoryActions.Update, this.destroy$).subscribe(() => {
      this.init();
    });
  }

  onHeroSelected(heroId: string | undefined): void {
    if (heroId === this.heroAdr + '_' + this.heroId) {
      return;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.INVENTORY, heroId?.split('_')[0], heroId?.split('_')[1]]);
  }

  onSelectInventory({ id }) {
    this.updateTabParam(id);

    this.currentInventoryView = id;
  }

  setItemSorting(query: string): void {
    this.currentItemsSort = { query };

    this.changeDetectorRef.detectChanges();
  }

  isItemEligibleForHero(item: ItemEntity) {
    return (
      item.meta.requirements.strength <= this.hero.core.strength &&
      item.meta.requirements.dexterity <= this.hero.core.dexterity &&
      item.meta.requirements.energy <= this.hero.core.energy &&
      item.meta.requirements.vitality <= this.hero.core.vitality &&
      item.durability > 0
    );
  }

  switchViewTo(view: INVENTORY_VIEW): void {
    this.currentInventoryView = view;
  }

  itemAction(data: ItemActionType) {
    console.log('use', data);
    if (data?.useItem) {
      this.use(data.useItem);
    }
  }

  onChangeViewMode() {
    this.viewMode = { query: this.viewMode.query === VIEW_MODE.LIST ? VIEW_MODE.ICON : VIEW_MODE.LIST };
  }

  openFilter() {
    this.filtersDialogRef = this.dialog.open(ItemsFiltersDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        filters: this.filters,
        general: this.generalFilters,
        isSell: false,
        isPriceDisabled: true,
      },
    });

    this.filtersDialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.filters = data.filters;
        this.generalFilters = data.general;

        this.onlyConcreteItemType = -1;
        this.onlyConcreteHeroes = 0;
        this.getPositionsPerPageWithSortAndFilter();
      }
    });
  }

  private getPositionsPerPageWithSortAndFilter() {
    this.filteredItems = [];
    this.filteredItems = this.sortItems();

    this.changeDetectorRef.detectChanges();
  }

  sortItems() {
    let items: ItemEntityExtendedType[] = this.items;

    if (!!this.currentFilterQuery?.query) {
      items = items.filter(
        item =>
          getItemNameBySymbol(item.meta.symbol)
            .toLowerCase()
            .indexOf(this.currentFilterQuery.query || '') >= 0,
      );
    }

    if (this.filters?.length) {
      const filters = this.filters ?? [];
      items = items.filter(item => {
        if (filters.length === 0) {
          return true;
        }

        let result = true;
        let atLeastOneOrExist = true;
        // if we have at least one OR filter atLeastOneOrExist should be set on true during search
        if (filters.filter(f => f.and !== CHECKBOX_STATE.CHECKED).length > 0) {
          atLeastOneOrExist = false;
        }

        for (const f of filters) {
          // if at least one OR success no sense check other
          if ((f.and !== CHECKBOX_STATE.CHECKED && atLeastOneOrExist) || !f.attribute) {
            continue;
          }

          let exist = false;
          if (f.filterType === FILTER_TYPE.COMMON) {
            exist = item.attributes[f.attribute.id] >= +f.value;
          }
          if (f.filterType === FILTER_TYPE.SKILL_BUFF_CASTER) {
            exist =
              item.buffInfo?.casterAttributes[f.attribute.id] >= +f.value ||
              item.buffInfo?.targetAttributes[f.attribute.id] >= +f.value;
          }
          if (f.filterType === FILTER_TYPE.IMPACT) {
            exist = item.consumableInfo?.attributes[f.attribute.id] >= +f.value;
            // also check stats
            if (!exist) {
              if (+f.attribute.id === ATTRIBUTES.LIFE) {
                exist = (item.consumableInfo?.buffStats?.life ?? 0) >= +f.value;
              }
              if (+f.attribute.id === ATTRIBUTES.MANA) {
                exist = (item.consumableInfo?.buffStats?.mana ?? 0) >= +f.value;
              }
              if (+f.attribute.id === ATTRIBUTES.LIFE_CHANCES) {
                exist = (item.consumableInfo?.buffStats?.lifeChances ?? 0) >= +f.value;
              }
            }
          }
          if (f.filterType === FILTER_TYPE.MAGIC_ATTACK) {
            if (f.attribute.id === 'manaConsumption') {
              exist = (item.meta?.manaCost ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'minDmg') {
              exist = (item.magicAttackInfo?.minDmg ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'maxDmg') {
              exist = (item.magicAttackInfo?.maxDmg ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'attributesFactorDexterity') {
              exist = (item.magicAttackInfo?.attributesFactor.dexterity ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'attributesFactorEnergy') {
              exist = (item.magicAttackInfo?.attributesFactor.energy ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'attributesFactorStrength') {
              exist = (item.magicAttackInfo?.attributesFactor.strength ?? 0) >= +f.value;
            }
            if (f.attribute.id === 'attributesFactorVitality') {
              exist = (item.magicAttackInfo?.attributesFactor.vitality ?? 0) >= +f.value;
            }
          }

          if (!exist) {
            if (f.and === CHECKBOX_STATE.CHECKED) {
              result = false;
              break;
            }
          } else if (f.and !== CHECKBOX_STATE.CHECKED) {
            atLeastOneOrExist = true;
          }
        }
        if (!atLeastOneOrExist) {
          return false;
        }
        return result;
      });
    }

    if (this.generalFilters) {
      const keys = Object.keys(this.generalFilters);

      items = items.filter(item => {
        return keys.every(
          key =>
            this.generalFilters &&
            item[key] >= (this.generalFilters[key].min ?? '0') &&
            (!this.generalFilters[key].max || item[key] <= this.generalFilters[key].max),
        );
      });
    }

    const count = countItems(items);

    items = items.filter(it => !it.meta.isConsumableItem);

    Object.keys(count).forEach(key => {
      const found = this.items.find(it => it.meta.name === key);
      if (found) {
        items = [
          ...items,
          { ...found, count: count[key], countFormatted: count[key] > 99 ? '99+' : String(count[key]) },
        ];
      }
    });

    return items.filter(it => {
      return this.onlyConcreteItemType === -1 || it.meta.itemType === this.onlyConcreteItemType;
    });
  }

  use(item: ItemEntity) {
    this.logger.trace('use item', item);
    this.useWaiting = true;

    this.changeDetectorRef.markForCheck();
    this.itemControllerService
      .use$(this.account, this.chainId, item.meta.id, item.itemId, this.hero.meta.id, this.hero.heroId)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.useWaiting = false;
        // refresh inventory
        this.loadHeroInfo();
        this.changeDetectorRef.markForCheck();
      });
  }

  equip(data: { item: ItemEntity; slot: number }) {
    if (data) {
      if (data.item.meta.itemType === ITEM_TYPE.TWO_HAND) {
        data.slot = ITEM_SLOT.TWO_HAND;
      }

      this.equipWaiting = true;

      this.changeDetectorRef.markForCheck();

      this.itemControllerService
        .equip$(
          this.account,
          this.chainId,
          [data.item.meta.id],
          [data.item.itemId],
          this.hero.meta.id,
          this.hero.heroId,
          [data.slot],
        )
        .pipe(
          finalize(() => {}),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.equipWaiting = false;
          this.isNeedToUpdateHero = true;
          // refresh inventory
          this.loadHeroInfo();
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  takeOff(data: { item?: ItemEntity; slot?: number } | undefined) {
    if (data && data.item) {
      if (data.item.meta.itemType === ITEM_TYPE.TWO_HAND) {
        data.slot = ITEM_SLOT.TWO_HAND;
      }

      const item = data.item;
      this.equipWaiting = true;

      this.changeDetectorRef.markForCheck();

      this.itemControllerService
        .takeOff$(
          this.account,
          this.chainId,
          [item ? item.meta.id : ''],
          [item ? item.itemId : 0],
          this.hero.meta.id,
          this.hero.heroId,
          [data && data.slot ? data.slot : 0],
        )
        .pipe(
          finalize(() => {}),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.equipWaiting = false;
          this.isNeedToUpdateHero = true;
          // refresh inventory
          this.loadHeroInfo();
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  takeOffAllEmit() {
    this.equipWaiting = true;
    const itemIds: number[] = [];
    const itemAddresses: string[] = [];
    const itemSlots: number[] = [];

    this.hero.items.forEach(item => {
      itemIds.push(item.itemId);
      itemAddresses.push(item.meta.id);
      itemSlots.push(item.equippedSlot ?? 0);
    });

    if (itemIds.length === 0) {
      this.logger.trace('No items to take off all');
      return;
    }

    this.changeDetectorRef.markForCheck();
    this.itemControllerService
      .takeOff$(this.account, this.chainId, itemAddresses, itemIds, this.hero.meta.id, this.hero.heroId, itemSlots)
      .pipe(
        finalize(() => {}),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.equipWaiting = false;
        this.isNeedToUpdateHero = true;
        // refresh inventory
        this.loadHeroInfo();
        this.changeDetectorRef.markForCheck();
      });
  }

  setInventoryItemTypeFilter({ id }): void {
    this.currentFilterItemClass = Number(id);
  }

  close(): void {
    this.dialogRef.close(this.isNeedToUpdateHero);
  }

  private updateTabParam(id: string) {
    this.router.navigate([], {
      queryParams: {
        'inventory-tab': `inventory-${this.translateService
          .instant(this.inventoryTypes.find(it => it.id === id)!.label)
          .toLowerCase()}`,
      },
      queryParamsHandling: 'merge',
    });
  }

  init() {
    if (this.account && this.chainId) {
      this.loadHeroInfo();
    }
  }

  private loadHeroInfo() {
    forkJoin({
      hero: this.subgraphService.hero$(this.heroAdr, this.heroId),
      isStaked: this.reinforcementService.isStakedV2$(this.chainId, this.heroAdr, this.heroId),
    })
      .pipe(
        finalize(() => {}),
        takeUntil(this.destroy$),
      )
      .subscribe(({ hero, isStaked }) => {
        this.logger.trace('hero', hero);
        this.isStaked = isStaked;
        if (hero) {
          this.hero = hero;

          this.equippedItemBySlots.clear();
          for (const eqItem of this.hero.items) {
            this.equippedItemBySlots.set(eqItem.equippedSlot ?? 0, eqItem);
          }

          this.loadItems();
        }
      });
  }

  private loadItems() {
    this.subgraphService
      .usersItems$(this.account)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(items => {
        this.items = items;

        this.getPositionsPerPageWithSortAndFilter();

        this.availableItemsBySlots.clear();
        for (const item of this.items) {
          if (
            item.meta.itemType === ITEM_TYPE.TWO_HAND &&
            (this.equippedItemBySlots.has(ITEM_SLOT.LEFT_HAND) || this.equippedItemBySlots.has(ITEM_SLOT.RIGHT_HAND))
          ) {
            continue;
          }

          if (this.isItemEligibleForHero(item)) {
            const slots = ITEM_TYPE_TO_ITEM_SLOTS_MAPPING.get(item.meta.itemType) ?? [];
            for (const slot of slots) {
              const _items = this.availableItemsBySlots.get(slot) ?? [];
              _items.push(item);
              this.availableItemsBySlots.set(slot, _items);
            }
          }
        }

        this.loading = false;

        this.changeDetectorRef.detectChanges();

        this.mediator.dispatch(new LoadingActions.PageLoaded(MAIN_ROUTES.INVENTORY));
      });
  }

  private resetInventorySlots(): void {
    rangeArray(0, this.itemSlotsArray.length).forEach(i => {
      this.availableItemsBySlots.set(i, []);
    });
  }

  protected readonly VIEW_MODE = VIEW_MODE;
}
