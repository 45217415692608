<div
  [class.g-flex]="!isUsdNextLine"
  [class.g-flex-column]="isUsdNextLine"
  [class.g-flex--align-center]="isUsdNextLine">
  <div class="g-flex g-flex--align-center">
    @if (tokenName) {
      <img
        class="balance__icon shadow__icon"
        [class.balance__icon--reverse]="isReverse"
        [src]="'assets/images/ui/icons/tokens/' + tokenName + '.png'"
        alt="" />
    }

    <div
      class="balance__text"
      [class.app-btn_font-size]="size === 'default'"
      [class.app-btn-small_font-size]="size === 'small'"
      [class.g-flex-column]="balanceUnformatted && balanceUnformatted > 15000"
      [class.balance__text-column]="balanceUnformatted && balanceUnformatted > 15000">
      @if (checkBalance()) {
        <span>
          {{ balance }}
        </span>

        @if (isShowBalanceUsd && balanceUsd && (+balanceUsd > 0 || isShowZeroBalanceUsd) && !isUsdNextLine) {
          <span> (${{ formatAmount(balanceUsd) }}) </span>
        }
      } @else {
        <ng-content></ng-content>
      }
    </div>
  </div>

  @if (isShowBalanceUsd && balanceUsd && (+balanceUsd > 0 || isShowZeroBalanceUsd) && isUsdNextLine) {
    <div>${{ formatAmount(balanceUsd) }}</div>
  }
</div>
