import { Dialog } from '@angular/cdk/dialog';
import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';

@Component({
  selector: 'app-dialog-title',
  standalone: true,
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
  imports: [NgStyle, LoadingSmallComponent, ScratchComponent],
})
export class DialogTitleComponent {
  @Input() title = '';
  @Input() titleStyles = {};
  @Input() subTitle = '';
  @Input() icon = '';
  @Input() iconWidth = 0;
  @Input() canClose = true;
  @Input() descTitle = '';
  @Input() descText = '';
  @Input() hasTitleScratch = true;
  @Input() isShowBack = false;
  @Input() isShowLoader = false;

  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dialog: Dialog) {}

  onShowDescDialog() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.descTitle,
        infoDesc: this.descText,
      },
    });
  }
}
