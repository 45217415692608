/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  GameObjectController,
  GameObjectControllerInterface,
} from "../GameObjectController";

const _abi = [
  {
    inputs: [],
    name: "EmptyObjects",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDungeonFactory",
    type: "error",
  },
  {
    inputs: [],
    name: "FightDelay",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "GenObjectIdBiomeOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "GenObjectIdIdOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "subType",
        type: "uint256",
      },
    ],
    name: "GenObjectIdSubTypeOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "value",
        type: "int256",
      },
    ],
    name: "IntOutOfRange",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "LengthsMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "ObjectNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "chance",
        type: "uint32",
      },
    ],
    name: "TooHighChance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "objectSubType",
        type: "uint8",
      },
    ],
    name: "UnknownObjectTypeForSubtype",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "objectType",
        type: "uint8",
      },
    ],
    name: "UnknownObjectTypeGoc1",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "objectType",
        type: "uint8",
      },
    ],
    name: "UnknownObjectTypeGoc2",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "objectType",
        type: "uint8",
      },
    ],
    name: "UnknownObjectTypeGocLib1",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "objectType",
        type: "uint8",
      },
    ],
    name: "UnknownObjectTypeGocLib2",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "chances",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "maxChances",
        type: "uint32",
      },
    ],
    name: "WrongChances",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongGetObjectTypeInput",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroChance",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroStoryIdAction",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroValue",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroValueNotAllowed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "eventId",
            type: "uint16",
          },
          {
            internalType: "enum IGOC.ObjectSubType",
            name: "subType",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "goodChance",
            type: "uint32",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "goodAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "badAttributes",
            type: "tuple",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "int32",
            name: "heal",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaRegen",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "lifeChancesRecovered",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "damage",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaConsumed",
            type: "int32",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
        ],
        indexed: false,
        internalType: "struct IGOC.EventRegInfo",
        name: "eventRegInfo",
        type: "tuple",
      },
    ],
    name: "EventRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        components: [
          {
            internalType: "uint16",
            name: "monsterId",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "enum IGOC.ObjectSubType",
            name: "subType",
            type: "uint8",
          },
          {
            internalType: "uint8[]",
            name: "attributeIds",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "attributeValues",
            type: "int32[]",
          },
          {
            internalType: "uint8",
            name: "level",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "race",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "maxDropItems",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "attackToken",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "attackTokenId",
            type: "uint64",
          },
          {
            internalType: "uint8",
            name: "attackType",
            type: "uint8",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
        ],
        indexed: false,
        internalType: "struct IGOC.MonsterGenInfo",
        name: "monsterGenInfo",
        type: "tuple",
      },
    ],
    name: "MonsterRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "ObjectRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "dungeonId",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "enum IGOC.ObjectType",
        name: "objectType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "stageId",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "iteration",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
      {
        components: [
          {
            internalType: "bool",
            name: "kill",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "completed",
            type: "bool",
          },
          {
            internalType: "address",
            name: "heroToken",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "int32",
            name: "heal",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaRegen",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "lifeChancesRecovered",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "damage",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaConsumed",
            type: "int32",
          },
          {
            internalType: "uint32",
            name: "objectId",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint256",
            name: "heroTokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "iteration",
            type: "uint256",
          },
          {
            internalType: "uint32[]",
            name: "rewriteNextObject",
            type: "uint32[]",
          },
        ],
        indexed: false,
        internalType: "struct IGOC.ActionResult",
        name: "result",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "salt",
        type: "uint256",
      },
    ],
    name: "ObjectResultEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "StoryRegistered",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "dungeonId",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "stageId",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "action",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "kill",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "completed",
            type: "bool",
          },
          {
            internalType: "address",
            name: "heroToken",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "int32",
            name: "heal",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaRegen",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "lifeChancesRecovered",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "damage",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaConsumed",
            type: "int32",
          },
          {
            internalType: "uint32",
            name: "objectId",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint256",
            name: "heroTokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "iteration",
            type: "uint256",
          },
          {
            internalType: "uint32[]",
            name: "rewriteNextObject",
            type: "uint32[]",
          },
        ],
        internalType: "struct IGOC.ActionResult",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "getEventInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "goodChance",
            type: "uint32",
          },
          {
            internalType: "bytes32[]",
            name: "goodAttributes",
            type: "bytes32[]",
          },
          {
            internalType: "bytes32[]",
            name: "badAttributes",
            type: "bytes32[]",
          },
          {
            internalType: "bytes32",
            name: "statsChange",
            type: "bytes32",
          },
          {
            internalType: "bytes32[]",
            name: "mintItems",
            type: "bytes32[]",
          },
        ],
        internalType: "struct IGOC.EventInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFightDelay",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "objId",
        type: "uint32",
      },
    ],
    name: "getIteration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "getLastHeroFightTs",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "getMonsterInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint16",
            name: "monsterId",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "enum IGOC.ObjectSubType",
            name: "subType",
            type: "uint8",
          },
          {
            internalType: "uint8[]",
            name: "attributeIds",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "attributeValues",
            type: "int32[]",
          },
          {
            internalType: "uint8",
            name: "level",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "race",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "maxDropItems",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "attackToken",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "attackTokenId",
            type: "uint64",
          },
          {
            internalType: "uint8",
            name: "attackType",
            type: "uint8",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IGOC.MonsterGenInfo",
        name: "mGenInfo",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bool",
            name: "generated",
            type: "bool",
          },
          {
            internalType: "uint8",
            name: "turnCounter",
            type: "uint8",
          },
          {
            internalType: "int32",
            name: "hp",
            type: "int32",
          },
          {
            internalType: "uint32",
            name: "amplifier",
            type: "uint32",
          },
        ],
        internalType: "struct IGOC.GeneratedMonster",
        name: "gen",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
    ],
    name: "getMonsterMultiplier",
    outputs: [
      {
        internalType: "int32",
        name: "",
        type: "int32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "enum IGOC.ObjectSubType",
        name: "subType",
        type: "uint8",
      },
    ],
    name: "getObjectIds",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "getObjectMeta",
    outputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "objectSubType",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "getObjectTypeBySubType",
    outputs: [
      {
        internalType: "enum IGOC.ObjectType",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8[]",
        name: "cTypes",
        type: "uint8[]",
      },
      {
        internalType: "uint32[]",
        name: "chances",
        type: "uint32[]",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "getRandomObject",
    outputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "getStoryId",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "objId",
        type: "uint32",
      },
    ],
    name: "isAvailableForHero",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "isBattleObject",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "open",
    outputs: [
      {
        internalType: "uint256",
        name: "iteration",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "eventId",
            type: "uint16",
          },
          {
            internalType: "enum IGOC.ObjectSubType",
            name: "subType",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "goodChance",
            type: "uint32",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "goodAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "badAttributes",
            type: "tuple",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "int32",
            name: "heal",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaRegen",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "lifeChancesRecovered",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "damage",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaConsumed",
            type: "int32",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IGOC.EventRegInfo",
        name: "regInfo",
        type: "tuple",
      },
    ],
    name: "registerEvent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint16",
            name: "monsterId",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "enum IGOC.ObjectSubType",
            name: "subType",
            type: "uint8",
          },
          {
            internalType: "uint8[]",
            name: "attributeIds",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "attributeValues",
            type: "int32[]",
          },
          {
            internalType: "uint8",
            name: "level",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "race",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "maxDropItems",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "attackToken",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "attackTokenId",
            type: "uint64",
          },
          {
            internalType: "uint8",
            name: "attackType",
            type: "uint8",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IGOC.MonsterGenInfo",
        name: "monsterGenInfo",
        type: "tuple",
      },
    ],
    name: "registerMonster",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "enum IGOC.ObjectSubType",
        name: "subType",
        type: "uint8",
      },
    ],
    name: "registerStory",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "removeObject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class GameObjectController__factory {
  static readonly abi = _abi;
  static createInterface(): GameObjectControllerInterface {
    return new Interface(_abi) as GameObjectControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): GameObjectController {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as GameObjectController;
  }
}
