/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  StatController,
  StatControllerInterface,
} from "../StatController";

const _abi = [
  {
    inputs: [],
    name: "ErrorCannotRemoveItemFromMap",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "ErrorConsumableItemIsUsed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorExperienceMustNotDecrease",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorForbidden",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "itemType",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "itemSlot",
        type: "uint8",
      },
    ],
    name: "ErrorItemNotEligibleForTheSlot",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorItemNotInSlot",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorItemSlotBusy",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "slot",
        type: "uint8",
      },
    ],
    name: "ErrorItemSlotBusyHand",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorMaxLevel",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotEnoughExperience",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotHeroController",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotItemController",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorWrongLevelUpSum",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorZeroKarmaNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    name: "OutOfBounds",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
    ],
    name: "TooLowX",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expected",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "actual",
        type: "uint256",
      },
    ],
    name: "UnexpectedValue",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "UnknownHeroClass",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "add",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "temporally",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
    ],
    name: "BonusAttributesChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "ConsumableUsed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint32",
            name: "level",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "life",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lifeChances",
            type: "uint32",
          },
        ],
        indexed: false,
        internalType: "struct IStatController.ChangeableStats",
        name: "change",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "increase",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
    ],
    name: "CurrentStatsChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "GlobalCustomDataChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "HeroCustomDataChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "itemType",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "itemSlot",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "itemToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "itemTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "equip",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
    ],
    name: "HeroItemSlotChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        indexed: false,
        internalType: "struct IStatController.CoreAttributes",
        name: "change",
        type: "tuple",
      },
    ],
    name: "LevelUp",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint32",
            name: "level",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "life",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lifeChances",
            type: "uint32",
          },
        ],
        indexed: false,
        internalType: "struct IStatController.ChangeableStats",
        name: "stats",
        type: "tuple",
      },
    ],
    name: "NewHeroInited",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "RemoveConsumableUsage",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
    ],
    name: "TemporallyAttributesCleared",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "heroToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "heroTokenId",
            type: "uint256",
          },
          {
            internalType: "uint32",
            name: "heroLevel",
            type: "uint32",
          },
          {
            internalType: "address[]",
            name: "buffTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "buffTokenIds",
            type: "uint256[]",
          },
        ],
        internalType: "struct IStatController.BuffInfo",
        name: "info",
        type: "tuple",
      },
    ],
    name: "buffHero",
    outputs: [
      {
        internalType: "int32[]",
        name: "",
        type: "int32[]",
      },
      {
        internalType: "int32",
        name: "",
        type: "int32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "heroToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "heroTokenId",
            type: "uint256",
          },
          {
            internalType: "int32[]",
            name: "changeAttributes",
            type: "int32[]",
          },
          {
            internalType: "bool",
            name: "add",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "temporally",
            type: "bool",
          },
        ],
        internalType: "struct IStatController.ChangeAttributesInfo",
        name: "info",
        type: "tuple",
      },
    ],
    name: "changeBonusAttributes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint32",
            name: "level",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "life",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lifeChances",
            type: "uint32",
          },
        ],
        internalType: "struct IStatController.ChangeableStats",
        name: "change",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "increase",
        type: "bool",
      },
    ],
    name: "changeCurrentStats",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "heroTokenId",
        type: "uint64",
      },
      {
        internalType: "uint256",
        name: "itemType",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "itemSlot",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "itemToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemTokenId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "equip",
        type: "bool",
      },
    ],
    name: "changeHeroItemSlot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "clearTemporallyAttributes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "clearUsedConsumables",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
    ],
    name: "globalCustomData",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "heroAttribute",
    outputs: [
      {
        internalType: "int32",
        name: "",
        type: "int32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "",
        type: "int32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroAttributesLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroBaseAttributes",
    outputs: [
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        internalType: "struct IStatController.CoreAttributes",
        name: "core",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroBonusAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "",
        type: "int32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
    ],
    name: "heroCustomData",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "heroInitialAttributes",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "core",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "minDamage",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxDamage",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "attackRating",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "defense",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "blockRating",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "life",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "mana",
                type: "uint256",
              },
            ],
            internalType: "struct StatLib.BaseMultiplier",
            name: "multiplier",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "life",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "mana",
                type: "uint256",
              },
            ],
            internalType: "struct StatLib.LevelUp",
            name: "levelUp",
            type: "tuple",
          },
          {
            internalType: "int32",
            name: "baseLifeChances",
            type: "int32",
          },
        ],
        internalType: "struct StatLib.InitialHero",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "heroTokenId",
        type: "uint64",
      },
      {
        internalType: "uint8",
        name: "itemSlot",
        type: "uint8",
      },
    ],
    name: "heroItemSlot",
    outputs: [
      {
        internalType: "bytes32",
        name: "nftPacked",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "heroItemSlots",
    outputs: [
      {
        internalType: "uint8[]",
        name: "",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroStats",
    outputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "level",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "life",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lifeChances",
            type: "uint32",
          },
        ],
        internalType: "struct IStatController.ChangeableStats",
        name: "result",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "heroTemporallyAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "",
        type: "int32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "initNewHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "isConsumableUsed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "isHeroAlive",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "level",
        type: "uint32",
      },
    ],
    name: "levelExperience",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        internalType: "struct IStatController.CoreAttributes",
        name: "change",
        type: "tuple",
      },
    ],
    name: "levelUp",
    outputs: [
      {
        internalType: "uint256",
        name: "newLvl",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "level",
        type: "uint32",
      },
    ],
    name: "levelUpExperienceRequired",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "registerConsumableUsage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "int32[]",
        name: "prevAttributes",
        type: "int32[]",
      },
    ],
    name: "restoreLifeAndMana",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "setGlobalCustomData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "index",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "setHeroCustomData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class StatController__factory {
  static readonly abi = _abi;
  static createInterface(): StatControllerInterface {
    return new Interface(_abi) as StatControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): StatController {
    return new Contract(address, _abi, runner) as unknown as StatController;
  }
}
