export const ABI_ERRORS = [
  {
    inputs: [
      {
        internalType: 'int32',
        name: 'a',
        type: 'int32',
      },
      {
        internalType: 'int32',
        name: 'b',
        type: 'int32',
      },
    ],
    name: 'AbsDiff',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'deployed',
        type: 'address',
      },
    ],
    name: 'AlreadyDeployed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyGuildMember',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyHaveReinforcement',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyInitialized',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyRegistered',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyStaked',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'pageId',
        type: 'uint16',
      },
      {
        internalType: 'uint16',
        name: 'pageIdFromAnswerHash',
        type: 'uint16',
      },
    ],
    name: 'AnswerPageIdMismatch',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'storyId',
        type: 'uint16',
      },
      {
        internalType: 'uint16',
        name: 'storyIdFromAnswerHash',
        type: 'uint16',
      },
    ],
    name: 'AnswerStoryIdMismatch',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ApproveToZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionBidClosed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionBidNotFound',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'positionId',
        type: 'uint256',
      },
    ],
    name: 'AuctionBidOpened',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionBuyerOnly',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionEnded',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionGuildWithShelterCannotBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionNotEnded',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionPositionClosed',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'positionId',
        type: 'uint256',
      },
    ],
    name: 'AuctionPositionNotSupported',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'positionId',
        type: 'uint256',
      },
    ],
    name: 'AuctionPositionOpened',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionSellerCannotBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AuctionSellerOnly',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'Broken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'BurnAmountExceedsBalance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'callResultData',
        type: 'bytes',
      },
    ],
    name: 'CallFailed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CannotApplyNotLastBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CannotCloseLastBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CannotRemoveGuildOwnerFromNotEmptyGuild',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'Consumable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'itemIndex',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: 'items',
        type: 'address[]',
      },
    ],
    name: 'DoubleItemUsageForbidden',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'dungNum',
        type: 'uint16',
      },
    ],
    name: 'DungeonAlreadySpecific',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'dungNum',
        type: 'uint16',
      },
    ],
    name: 'DungeonAlreadySpecific2',
    type: 'error',
  },
  {
    inputs: [],
    name: 'EmptyNameNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'EmptyObjects',
    type: 'error',
  },
  {
    inputs: [],
    name: 'EquipForbiddenInDungeon',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'EquippedItemIsNotAllowedToTransfer',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorAlreadyInDungeon',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorCannotRemoveItemFromMap',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'ErrorConsumableItemIsUsed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorDungeonBusy',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorDungeonCompleted',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorDungeonIsFreeAlready',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorEquipForbidden',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorExperienceMustNotDecrease',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorForbidden',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'heroToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'heroTokenId',
        type: 'uint256',
      },
    ],
    name: 'ErrorHeroIsDead',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'heroToken',
        type: 'address',
      },
    ],
    name: 'ErrorHeroIsNotRegistered',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorHeroLevelStartFrom1',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorHeroNotInDungeon',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'biome',
        type: 'uint256',
      },
    ],
    name: 'ErrorIncorrectBiome',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'itemType',
        type: 'uint256',
      },
      {
        internalType: 'uint8',
        name: 'itemSlot',
        type: 'uint8',
      },
    ],
    name: 'ErrorItemNotEligibleForTheSlot',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorItemNotInSlot',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorItemSlotBusy',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'slot',
        type: 'uint8',
      },
    ],
    name: 'ErrorItemSlotBusyHand',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'heroLevel',
        type: 'uint256',
      },
    ],
    name: 'ErrorLevelTooLow',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorMaxLevel',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'heroBiome',
        type: 'uint8',
      },
    ],
    name: 'ErrorNoDungeonsForBiome',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNoEligibleDungeons',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotAllowedSender',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotBiome',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotChances',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotCompleted',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorNotDeployer',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorNotDungeonFactory',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'heroToken',
        type: 'address',
      },
      {
        internalType: 'uint16',
        name: 'dungNum',
        type: 'uint16',
      },
    ],
    name: 'ErrorNotEligible',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotEnoughExperience',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint32',
        name: 'mana',
        type: 'uint32',
      },
      {
        internalType: 'uint256',
        name: 'requiredMana',
        type: 'uint256',
      },
    ],
    name: 'ErrorNotEnoughMana',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotGoc',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotGuildController',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorNotHeroController',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorNotItemController',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotObject1',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotObject2',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'ErrorNotObjectController',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ErrorNotOwner',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotReady',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotShelterController',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotStages',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorNotStoryController',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'treasureToken',
        type: 'address',
      },
    ],
    name: 'ErrorNotValidTreasureToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorOnlyEoa',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorPaused',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorReinforcementRequiresThreeSkills',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'heroLevel',
        type: 'uint256',
      },
    ],
    name: 'ErrorWrongLevel',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorWrongLevelUpSum',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'multiplier',
        type: 'uint256',
      },
    ],
    name: 'ErrorWrongMultiplier',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'stage',
        type: 'uint256',
      },
    ],
    name: 'ErrorWrongStage',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ErrorZeroKarmaNotAllowed',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'shelterLevel',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'allowedShelterLevel',
        type: 'uint8',
      },
    ],
    name: 'ExitFromDungeonWrongShelterLevel',
    type: 'error',
  },
  {
    inputs: [],
    name: 'FightDelay',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'shelterId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'FreeShelterItemsAreNotAllowed',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'biome',
        type: 'uint8',
      },
    ],
    name: 'GenObjectIdBiomeOverflow',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'GenObjectIdIdOverflow',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'subType',
        type: 'uint256',
      },
    ],
    name: 'GenObjectIdSubTypeOverflow',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'right',
        type: 'uint256',
      },
    ],
    name: 'GuildActionForbidden',
    type: 'error',
  },
  {
    inputs: [],
    name: 'GuildAlreadyHasShelter',
    type: 'error',
  },
  {
    inputs: [],
    name: 'GuildControllerOnly',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'level',
        type: 'uint256',
      },
    ],
    name: 'GuildHasMaxLevel',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'guildSize',
        type: 'uint256',
      },
    ],
    name: 'GuildHasMaxSize',
    type: 'error',
  },
  {
    inputs: [],
    name: 'GuildHasNoShelter',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'pointsAvailable',
        type: 'uint64',
      },
      {
        internalType: 'uint256',
        name: 'pointRequired',
        type: 'uint256',
      },
    ],
    name: 'GuildHasNotEnoughPvpPoints',
    type: 'error',
  },
  {
    inputs: [],
    name: 'GuildRequestNotActive',
    type: 'error',
  },
  {
    inputs: [],
    name: 'GuildRequestNotAvailable',
    type: 'error',
  },
  {
    inputs: [],
    name: 'HeroInDungeon',
    type: 'error',
  },
  {
    inputs: [],
    name: 'HeroTokensVaultAlreadySet',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'IdOverflow',
    type: 'error',
  },
  {
    inputs: [],
    name: 'IncompatibleInputString',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'int32',
        name: 'min',
        type: 'int32',
      },
      {
        internalType: 'int32',
        name: 'max',
        type: 'int32',
      },
    ],
    name: 'IncorrectMinMaxAttributeRange',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'kind',
        type: 'uint8',
      },
    ],
    name: 'IncorrectOtherItemTypeKind',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'shelterLevel',
        type: 'uint8',
      },
    ],
    name: 'IncorrectShelterLevel',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InsufficientAllowance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'int256',
        name: 'value',
        type: 'int256',
      },
    ],
    name: 'IntOutOfRange',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidHeroClass',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidProof',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
      {
        internalType: 'uint8',
        name: 'equippedSlot',
        type: 'uint8',
      },
    ],
    name: 'ItemAlreadyUsedInSlot',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ItemEquipped',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'ItemIsAlreadyEquipped',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ItemMetaTypeChanged',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ItemTypeChanged',
    type: 'error',
  },
  {
    inputs: [],
    name: 'LengthsMismatch',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'fee',
        type: 'uint8',
      },
    ],
    name: 'MaxFee',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'numSoldItems',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'limit',
        type: 'uint256',
      },
    ],
    name: 'MaxNumberItemsSoldToday',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'MaxValue',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'fee',
        type: 'uint8',
      },
    ],
    name: 'MinFee',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintToZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NameTaken',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'msgSender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'lootBoxKind',
        type: 'uint256',
      },
    ],
    name: 'NoAvailableLootBox',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NoHeroesAvailable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'payTokenAmount',
        type: 'uint256',
      },
    ],
    name: 'NoPayToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NoStakedHeroes',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'atype',
        type: 'uint256',
      },
    ],
    name: 'NotAType',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotAnswer',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'NotConsumable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'NotEOA',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotEnoughAllowance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'balance',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'requiredAmount',
        type: 'uint256',
      },
    ],
    name: 'NotEnoughAmount',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotEnoughBalance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'guildId',
        type: 'uint256',
      },
    ],
    name: 'NotEnoughGuildBankBalance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'usedPoints',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'pricePvpPoints',
        type: 'uint256',
      },
      {
        internalType: 'uint64',
        name: 'capactiy',
        type: 'uint64',
      },
    ],
    name: 'NotEnoughPvpPointsCapacity',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'balance',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'expectedBalance',
        type: 'uint256',
      },
    ],
    name: 'NotEnoughTokens',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'NotEquipped',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'NotExistToken',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'NotFutureGovernance',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotGlobalData',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'NotGovernance',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotGuildMember',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotHeroData',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotInitialized',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotItem1',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotItem2',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotMagic',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'NotMinter',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'positionId',
        type: 'uint256',
      },
    ],
    name: 'NotNftPositionNotSupported',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotOther',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint32',
        name: 'random',
        type: 'uint32',
      },
    ],
    name: 'NotRandom',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotShelterAuction',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotStaked',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotYourAttackItem',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotYourBuffItem',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotYourDebuffItem',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotZeroOtherItemMetaType',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ObjectNotFound',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OnlyShelterAuction',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OracleWrongInput',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OtherTypeItemNotRepairable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'length',
        type: 'uint256',
      },
    ],
    name: 'OutOfBounds',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pageId',
        type: 'uint256',
      },
    ],
    name: 'PageNotRemovedError',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopAlreadyAnnounced',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopAlreadyClaimed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopAuctionBidNotFound',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopAuctionEnded',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopAuctionNotEnded',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopBidAlreadyExists',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopBidClosed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopBidNotFound',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopIncorrect',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopNewBidTooLow',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopNoBids',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopNotBorrower',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopNotLender',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopNotOwner',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopPosFeeAbsurdlyHigh',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopPosFeeForInstantDealForbidden',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopPositionClosed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopPositionExecuted',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopPositionNotExecuted',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopTimeLock',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopTooEarlyToClaim',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopTooHighValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopTooLowBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongAddressValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongAmounts',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongBidAmount',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongId',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopWrongUintValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopZeroAToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopZeroCToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopZeroFeeRecipient',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PawnShopZeroOwner',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'positionId',
        type: 'uint256',
      },
    ],
    name: 'PositionNotSupported',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ReentrancyGuardReentrantCall',
    type: 'error',
  },
  {
    inputs: [],
    name: 'RequirementsToItemAttributes',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'notContract',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'tracingInfo',
        type: 'string',
      },
    ],
    name: 'SacraRelayCallToNotContract',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayDeadline',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayDelegationExpired',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callChainId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'blockChainId',
        type: 'uint256',
      },
    ],
    name: 'SacraRelayInvalidChainId',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callNonce',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'txNonce',
        type: 'uint256',
      },
    ],
    name: 'SacraRelayInvalidNonce',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayInvalidSignature',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'selector',
        type: 'bytes4',
      },
      {
        internalType: 'string',
        name: 'tracingInfo',
        type: 'string',
      },
    ],
    name: 'SacraRelayNoErrorSelector',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayNotDelegator',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayNotOperator',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SacraRelayNotOwner',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'errorBytes',
        type: 'bytes',
      },
      {
        internalType: 'string',
        name: 'tracingInfo',
        type: 'string',
      },
    ],
    name: 'SacraRelayUnexpectedReturnData',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SameIdsNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SecondGuildAdminIsNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterBidIsNotAllowedToBeUsed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterHasHeroesInside',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'shelterId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'ShelterHasNotItem',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterIsBusy',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterIsInUse',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterIsNotOwnedByTheGuild',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ShelterIsNotRegistered',
    type: 'error',
  },
  {
    inputs: [],
    name: 'StakeHeroNotStats',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'heroToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'heroId',
        type: 'uint256',
      },
    ],
    name: 'Staked',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TakeOffForbiddenInDungeon',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TokenTransferNotAllowed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TooBigName',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'augmentationLevel',
        type: 'uint8',
      },
    ],
    name: 'TooHighAgLevel',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'biome',
        type: 'uint256',
      },
    ],
    name: 'TooHighBiome',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint32',
        name: 'chance',
        type: 'uint32',
      },
    ],
    name: 'TooHighChance',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'random',
        type: 'uint256',
      },
    ],
    name: 'TooHighRandom',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'TooHighValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TooLongString',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TooLongUrl',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TooLowAmountForNewBid',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TooLowAmountToBid',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'x',
        type: 'uint256',
      },
    ],
    name: 'TooLowX',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'balance',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'TransferAmountExceedsBalance',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TransferToZeroAddress',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'expected',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'actual',
        type: 'uint256',
      },
    ],
    name: 'UnexpectedValue',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'UnionItemNotFound',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'attackType',
        type: 'uint256',
      },
    ],
    name: 'UnknownAttackType',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'heroClass',
        type: 'uint256',
      },
    ],
    name: 'UnknownHeroClass',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
    ],
    name: 'UnknownItem',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'objectSubType',
        type: 'uint8',
      },
    ],
    name: 'UnknownObjectTypeForSubtype',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'objectType',
        type: 'uint8',
      },
    ],
    name: 'UnknownObjectTypeGoc1',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'objectType',
        type: 'uint8',
      },
    ],
    name: 'UnknownObjectTypeGoc2',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'objectType',
        type: 'uint8',
      },
    ],
    name: 'UnknownObjectTypeGocLib1',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'objectType',
        type: 'uint8',
      },
    ],
    name: 'UnknownObjectTypeGocLib2',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'unionConfigId',
        type: 'uint256',
      },
    ],
    name: 'UnknownUnionConfig',
    type: 'error',
  },
  {
    inputs: [],
    name: 'UseForbiddenZeroPayToken',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'keyPassItem',
        type: 'address',
      },
    ],
    name: 'UserHasNoKeyPass',
    type: 'error',
  },
  {
    inputs: [],
    name: 'UserTokensVaultAlreadySet',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongAuctionPosition',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint32',
        name: 'chances',
        type: 'uint32',
      },
      {
        internalType: 'uint32',
        name: 'maxChances',
        type: 'uint32',
      },
    ],
    name: 'WrongChances',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongGetObjectTypeInput',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongGuild',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'item',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'countTokens',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'requiredCountTokens',
        type: 'uint256',
      },
    ],
    name: 'WrongListUnionItemTokens',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongSpecificDungeon',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongSymbolsInTheName',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newValue',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'actual',
        type: 'uint256',
      },
    ],
    name: 'WrongValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongWayToRegisterItem',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroAmount',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroAugmentation',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroChance',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroDurability',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroFragility',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroItemMetaType',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroLevel',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroLife',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroStoryIdAction',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroStoryIdRemoveStory',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroStoryIdStoryAction',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroValue',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ZeroValueNotAllowed',
    type: 'error',
  },
];
