import { CoreAddressesModel } from '@models/core-addresses.model';
import { PoolAddressesModel } from '@models/pool-addresses.model';
import { TokenInfo } from '@shared/constants/addresses/addresses.constant';

export const NEBULA_TESTNET_CORE = new CoreAddressesModel(
  '0x75e1e98650c119c4E3dCE3070CE6A5397Ed70c6a', // controller
  '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // gameToken
  '0x9D88DeB923c3fC633B364439c46CeA56D237D61E', // minter
  '0xCF66857b468740d6dbF9cE11929A9c03DDA12988', // goc
  '0xC423D7e3e1B7caF1AA5ce58EA0f3A91427Fd47ae', // reinforcementController
  '0xA60205802E1B5C6EC1CAFA3cAcd49dFeECe05AC9', // dungeonFactory
  '0xd0C1378c177E961D96c06b0E8F6E7841476C81Ef', // heroController
  '0xFE700D523094Cc6C673d78F1446AE0743C89586E', // itemController
  '0xC3B5d80E4c094B17603Ea8Bb15d2D31ff5954aAE', // oracle
  '0x6d85966b5280Bfbb479E0EBA00Ac5ceDfe8760D3', // statController
  '0x00aE29E31748898B8B404b4DcF076738F68439d4', // storyController
  '0x3bDbd2Ed1A214Ca4ba4421ddD7236ccA3EF088b6', // treasury
  '0x875976AeF383Fe4135B93C3989671056c4dEcDFF', // statReader
  '0x6ce857d3037e87465b003aCbA264DDF2Cec6D5E4', // multicall
  '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // magicToken
  '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // strengthToken
  '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // dexterityToken

  '0xC9d5917A0cb82450Cd687AF31eCAaC967D7F121C', // pawnsop
  '0x0C6868831c504Fb0bB61A54FEfC6464804380508', // PawnShopRouterFactory
  '', // network token
  '', // sponsored hero
  '0x52CEba41Da235Af367bFC0b0cCd3314cb901bB5F', // relay
  '0xeFBc16b8c973DecA383aAAbAB07153D2EB676556', // data storage
  '0xd1fD04d17BEb5D06a1af9D08fb3a8857B252e56C', // user controller
  '0x8d6479dF2c152F99C23493c8ebbaf63DC586024b', // item controller helper
  '0xF9E426dF37D75875b136d9D25CB9f27Ee9E43C4f', // guild controller
  '0xbF1e638871c59859DB851674c7F94EFcb0f40954', // shelterController
  '0x01D0b17AC7B72cD4b051840e27A2134F25C53265', // shelterAuctionController
  '0xda08F7DE9923acEe24CE292Ec2b20D45b1522Cb6', // rewardsPool
);

export const NEBULA_TESTNET_TOKEN_INFO = new Map<string, TokenInfo>([
  [NEBULA_TESTNET_CORE.gameToken.toLowerCase(), { symbol: 'SACRA', decimals: 18 }],
  [NEBULA_TESTNET_CORE.networkToken.toLowerCase(), { symbol: 'sFUEL', decimals: 18 }],
]);

export const NEBULA_TESTNET_POOLS = new PoolAddressesModel('');

export const NEBULA_TESTNET_DEX = {
  QUOTER_V2: '',
  SWAP_ROUTER_V2: '',
  NATIVE_USDC_POOL: '',
};

export function getNebulaFaucetByToken(token: string) {
  if (NEBULA_TESTNET_CORE.gameToken.toLowerCase() === token.toLowerCase()) {
    return '0xc8F61245876b987d090BDA931cd60dF1747b5C3c';
  } else {
    throw Error('No faucet for token ' + token);
  }
}

export const NEBULA_TESTNET_ITEMS = {
  SACRA_CONS_1_ITEM: '0x729F9C233BdeBcDA8A23394f9E4d94e5053A795D',
  SACRA_CONS_2_ITEM: '0x06aFdc7b90eE8570a0C6600Ecb1b80cCfdD80dF5',
  SACRA_CONS_3_ITEM: '0xb42Df1E6B2a79471c18ED626C53F2b8EaCE10b87',
  SACRA_CONS_4_ITEM: '0xC801956F5dA6D6d08a3B543acF4A98b34289C26A',
  SACRA_CONS_17_ITEM: '0x566F51b19651b5EecF1D60Ad8F08c936015C0dc1',
  SACRA_CONS_18_ITEM: '0x8626a577EA3139d14EF905B8EBE3cEbB395Ff358',
  SACRA_CONS_19_ITEM: '0xAA43e2cc199DC946b3D528c6E00ebb3F4CC2fC0e',
  SACRA_CONS_20_ITEM: '0xfEDE75CAD1dC48C9Ad5ca165e02c28Fa0Da351eB',
  SACRA_CONS_21_ITEM: '0x657aE82292ae96Ae36F3c155C3657DB991F33167',
  SACRA_CONS_27_ITEM: '0xd1FEAB51771E63915Ebc9Dd72537B1C6DB71e62a',
  SACRA_CONS_28_ITEM: '0xa281C7B40A9634BCD16B4aAbFcCE84c8F63Aedd0',
  SACRA_CONS_29_ITEM: '0xbCAA751ABac9f200E89BA001F4A75e023730Ea2d',
  SACRA_CONS_30_ITEM: '0xD35951C225A5B691462ff82B549e94AAF7dD1fbd',
  SACRA_CONS_31_ITEM: '0xbd21EC4b56A50aBF3C52ca9977C26291632Ce5A6',
  SACRA_CONS_32_ITEM: '0x8846715645A06a5c46309dC29623793D97795242',
  SACRA_CONS_35_ITEM: '0x8d0820528c5F5C8A0FB2d951C177089f3463fb83',
  SACRA_HELM_1_ITEM: '0x053bEc42CA1Cb2E58E27097E5EC3FDf3B7BEc767',
  SACRA_HELM_6_ITEM: '0x5824b054DcAdCA636d0FC8E8ab250172E967287e',
  SACRA_HELM_9_ITEM: '0x96cee247B587c19D5570dae254d57958e92D75f0',
  SACRA_BODY_1_ITEM: '0xbb0b5D4aeaEfc0Ed86d92A9ACeB2781146dC6917',
  SACRA_GLOVES_1_ITEM: '0xb54c1d161aFB8674Ab276608242cC02798aED0F2',
  SACRA_GLOVES_6_ITEM: '0x7eFC54ED20E32EA76497CB241c7E658E3B29B04B',
  SACRA_BELT_1_ITEM: '0xe926a29f531AC36A0D635a5494Fd8474b9a663aD',
  SACRA_BELT_5_ITEM: '0x0630d8cE3Aec9acEAD3B78fECe1Aff67a11a7195',
  SACRA_AMULET_1_ITEM: '0x873A168844524DBb078a6Be8e9b56e06630ce2de',
  SACRA_AMULET_2_ITEM: '0xc6fBc757ff0843811a3306E6f3bC9CB76158fE39',
  SACRA_AMULET_12_ITEM: '0x8A571137DA0d66c2528DA3A83F097fbA10D28540',
  SACRA_AMULET_13_ITEM: '0x2e75658b77D61a399c7E990b4b43f7b7EE21D9C3',
  SACRA_RING_1_ITEM: '0x350e9f6CD536e5Ed217452C50c4187293494ff3a',
  SACRA_RING_2_ITEM: '0x269A36957Bc7aDDee51c24AAC6f07c40dDFaCBC3',
  SACRA_RING_9_ITEM: '0x33DbC25D37479d9c1363eca8C3bBc7366F1E3336',
  SACRA_OFF_1_ITEM: '0xb1431a0Cd5E75A24ae82b2A5010772dcd2fb1c91',
  SACRA_OFF_2_ITEM: '0xf239F89992688cE2539cf637614Cc3f8866Ea433',
  SACRA_OFF_10_ITEM: '0x2207ecFa99E7776946c75B83f5c8BDC827A597C5',
  SACRA_BOOTS_1_ITEM: '0x849ecF35f711fFE183838DFdaaDFe105D4C0662a',
  SACRA_BOOTS_5_ITEM: '0x092D2c633D9928a3Aad25d9Ff307F26300FA274A',
  SACRA_OHW_1_ITEM: '0xD37fC11dEDfaa0fc3449b2BF5eDe864Ef6AaE1E3',
  SACRA_OHW_2_ITEM: '0x4088768580d7182a3e7C2Da6e8746F39f11DEF7D',
  SACRA_OHW_3_ITEM: '0x5418F150e59a3a0c544168C2f44C102D3c0D5A6D',
  SACRA_THW_1_ITEM: '0xA86F858e6687Dd6331bD8091534D832A08a58000',
  SACRA_THW_2_ITEM: '0x7f52D49C8A9779E93613Fb14cF07be1500ab9C3A',
  SACRA_FEAR_ITEM: '0x6C3246e749472879D1088C24Dacd2A37CAaEe9B1',
  SACRA_DEMON_SPEED_ITEM: '0x52542C6f5aa1fc445D5BE24A189CACb27286481d',
  SACRA_BRUTE_FORCE_ITEM: '0xd5c5fc773883289778092e864afE015979A10eb9',
  SACRA_SAVAGE_CHARM_ITEM: '0x7b32292BAe66A5Ef91ad8F58a98557b4222a0Bd6',
  SACRA_GOLDEN_EYE_ITEM: '0x3Fd0A8a975eC101748aE931B2d13711E04231920',
  SACRA_SABBAH_POISON_ITEM: '0x108C88666157f955c0f0fE8dbC7f4078120EcCdB',
  SACRA_SPIT_IN_FACE_ITEM: '0xe29d72E3f072A6B93F54F08C8644Dd3429Fe69a7',
  SACRA_ONSLAUGHT_ITEM: '0xd8FB34AEb9CEbb0A72d46A078978c9918b133579',
  SACRA_STRONG_BLOCK_ITEM: '0xa5218933721D2fa8Bb95e5f02D32d3FE0a9039F8',
  SACRA_BEEF_PATTY_ITEM: '0x376aAa179Ad57CaeEA7c758dB8b1A5345d1D6eF3',
  SACRA_HEAT_STRIKE_ITEM: '0xC6f6e9772361A75988C6CC248a3945a870FB1272',
  SACRA_STATIC_DISCHARGE_ITEM: '0x633b054bb63a0e13012e28167A916201892d122F',
  SACRA_CONS_5_ITEM: '0x46e8E75484eE655C374B608842ACd41B2eC3f11C',
  SACRA_CONS_6_ITEM: '0xF15277BbBA37591706eccdBf792280d37A9c4B9C',
  SACRA_CONS_7_ITEM: '0xe16deabA7F41De07e227444bB7F551c159253B83',
  SACRA_CONS_8_ITEM: '0x811eb43826CB1c8f83400449f967DdC507360728',
  SACRA_CONS_22_ITEM: '0xea22C04BC2aD994F73B7e4d537921a3D0f297B02',
  SACRA_HELM_2_ITEM: '0xb831c5A919983F88D2220E2fF591550513Dd2236',
  SACRA_HELM_7_ITEM: '0xbb6955535224aAF715Fe4Badc5178A0EF04C8Bf0',
  SACRA_BODY_2_ITEM: '0x087b137545dBe79594d76F9122A12bdf5cf12AD4',
  SACRA_BODY_6_ITEM: '0x80A5315e73F069B8a40FfB8EF2a34Cd570D96602',
  SACRA_BODY_8_ITEM: '0x83fc6Debb78ba0101dac4A19638689D5E3AaeF94',
  SACRA_GLOVES_2_ITEM: '0xc73670E9a38ABa2d5B41FB7518661ba8Be080264',
  SACRA_GLOVES_7_ITEM: '0xD23188c7dE30Ad8897a6fDF4D97aD1860613Cdf0',
  SACRA_BELT_2_ITEM: '0x6720927375d4566A453F0bBf522D845c1f8f10b8',
  SACRA_AMULET_3_ITEM: '0x292a18f9FBAE1A8A7253BD2174e0a0d22D8E1989',
  SACRA_AMULET_4_ITEM: '0xa828742888c3b9805Af5D4F128910ceA93Fb3cc1',
  SACRA_AMULET_9_ITEM: '0xE321F96C4731ce781a6eEb4E00a32B639ba6E91b',
  SACRA_RING_3_ITEM: '0xDe37A9C8Ae1F756d4FAEc78c22a9fd5452046281',
  SACRA_RING_4_ITEM: '0x81f2F5EC2eEBb1673e935a98C80f2EbF8fB3aa1b',
  SACRA_RING_10_ITEM: '0xfCef72d3Da8389fE36A8fb4790bAD3a4c0b9eAcD',
  SACRA_RING_11_ITEM: '0xE28E3146306Ff247b00cccE00D7893775B0FB696',
  SACRA_OFF_3_ITEM: '0xE8149c149cC1ca577A365dCaa1817494E4ea14f3',
  SACRA_OFF_4_ITEM: '0x11A4f8728A52790086b70DC040CF9269be686acD',
  SACRA_OFF_11_ITEM: '0x9617662F2050E240F622165c9a1d3190F5a75e1D',
  SACRA_BOOTS_2_ITEM: '0x0711F1F17F003E5c056dDd0716b792b455Ee9706',
  SACRA_OHW_4_ITEM: '0xaF2A836bB41f3ed9229D1071Af67879c6f124e50',
  SACRA_OHW_5_ITEM: '0x937655437b96C913CA072B00453123fa96782BcA',
  SACRA_OHW_6_ITEM: '0x037A0baF0b0c17100E3a95be4B981118c97AFecF',
  SACRA_THW_3_ITEM: '0x21d97B1adcD2A36756a6E0Aa1BAC3Cf6c0943c0E',
  SACRA_THW_4_ITEM: '0x7388dB7f747A1E3952D23c90032BB760104FC437',
  SACRA_THW_5_ITEM: '0x02b7A35c72b150c3d9175f0c6b0E9E49F0B5988a',
  SACRA_STEEL_SKIN_ITEM: '0x2e3dA9293b9DB4392B83b9668b4740fb55FA1843',
  SACRA_LIFEDRAIN_ITEM: '0xf5c32B020C334F523BEAeC5cAF9421B8bDE8227e',
  SACRA_FROSTBITE_ITEM: '0x3B9352eA340ff652529bF249920a1bE2739D08A5',
  SACRA_LIKE_A_PUSSY_ITEM: '0xF82671F67DCe6B646F8A9a1Db7832b13f006823C',
  SACRA_RAGE_ITEM: '0x3570CeC085974c1e7aC3dB2C68Ce65eD4f21ba94',
  SACRA_DEAD_STRIKE_ITEM: '0x48Ae660893b52B876731d02B86D29B5E9c0BFD5B',
  SACRA_FIRM_HAND_ITEM: '0xC70878D995c4319Fc6427Ca3161F7e4e335eCBa2',
  SACRA_JUSTICE_ITEM: '0x10d3b2A8fa95712cF2c86c1743320691D85396a9',
  SACRA_STEP_BACK_ITEM: '0x778612982f3CD78b523c7fa00C2eF6f35367985D',
  SACRA_PATIENCE_ITEM: '0x572749CD713cBfeacCF06F0c83B68e8c1C10A248',
  SACRA_TIME_AND_EXPERIENCE_ITEM: '0xA19f92D23B4cde7B145fe0539e17FC93A4a61316',
  SACRA_MELT_ITEM: '0x778945f3B35a533F5593A5B38d93e79Ec76bFf51',
  SACRA_ICE_SHARD_ITEM: '0x75b474BCC9D70A5D7194f57091394a8167a22d4C',
  SACRA_CONS_9_ITEM: '0xcB1C301C1966f4d26FfeF78EdD4de850392a40B1',
  SACRA_CONS_10_ITEM: '0x47011A7bdCbf5C82aC7F492DC3a07d0C53c8A471',
  SACRA_CONS_11_ITEM: '0x22390c10458153f3991E6d2780B10FFdD7Db2e1C',
  SACRA_CONS_12_ITEM: '0x14E0d5f9700599845dcD8B0Ec2B6904AD7531Ad6',
  SACRA_CONS_23_ITEM: '0x28E3bCC1348816665Bb163505658915f82f239BA',
  SACRA_CONS_24_ITEM: '0x3651465aa43AfC9bd45613cF3cD8D4439E60123C',
  SACRA_HELM_3_ITEM: '0x264ddD7692EBa6d95a08410db26b695583215B3e',
  SACRA_HELM_8_ITEM: '0x4Aca3228c815bA6e3E2BbF9241ed2C5aB677E40a',
  SACRA_BODY_3_ITEM: '0xe79Ff509AdEd427e8E773CBa36e094984217da51',
  SACRA_BODY_7_ITEM: '0x9E4358fCa9739893fcc7d79B9D40e370BA8A8Bd9',
  SACRA_GLOVES_3_ITEM: '0x50f59fa1c7ac605C5a2518Ca3B66e75ED9663f96',
  SACRA_GLOVES_8_ITEM: '0x525BB44884e149B99A809745C0c97989938CC970',
  SACRA_BELT_3_ITEM: '0x3E4e5195f86b77c44137a8D304Dd29f4597035CC',
  SACRA_AMULET_5_ITEM: '0xff01f27944Bc27Cd98bf792F8666Ebd7901e4cC9',
  SACRA_AMULET_6_ITEM: '0xE2f2C41FB031F367E8Cefa99098ab262914066c0',
  SACRA_AMULET_10_ITEM: '0xFB4c95568E1Bf5683af1c7FA6dbAaDaf675B555b',
  SACRA_AMULET_11_ITEM: '0x1FC1b426De80F2aD7961D07745EDF0693F16bacf',
  SACRA_RING_5_ITEM: '0x9b36556e737B0E5592CD4444B79bc5B6E6395998',
  SACRA_RING_6_ITEM: '0x79de355387BC0ba675191c49FD3ed59734ddd3d7',
  SACRA_OFF_5_ITEM: '0xF7bC1BCb6b237cAFa016Fa557B94222Fd9594e75',
  SACRA_OFF_6_ITEM: '0x2d1dF095C039153FaDE0e1A581EE5D0672085Bf2',
  SACRA_BOOTS_3_ITEM: '0xf5Aeb0591c6bE972f7d66e4aEc2dAF4eF8d23975',
  SACRA_BOOTS_6_ITEM: '0x3c7550B81ED82cf53Fe48F351b9054D93b8Cde0c',
  SACRA_OHW_7_ITEM: '0x574d055c0D6064f417766a39D6a1C2C13A267F53',
  SACRA_OHW_8_ITEM: '0xdc8e8131537Cb629eba6f8748be24E2E01efBDB5',
  SACRA_OHW_9_ITEM: '0xFf5cB1D25942058D7bd6DAa947adF6C6dc938A12',
  SACRA_OHW_12_ITEM: '0xf2f1FD7678E3d5d38c235150f2D6eAAD2D0497b2',
  SACRA_OHW_13_ITEM: '0x6575F1ba00D1dafAd82271398574cbC8f341453A',
  SACRA_OHW_14_ITEM: '0xDd46284101F71b10E468E03019a55516EF9F45F8',
  SACRA_THW_6_ITEM: '0xECe3b21Ca174905EeF087BBc7aCA59A95C45a57b',
  SACRA_THW_7_ITEM: '0xA21bd15a1c745A7abc06F33E3fD577277842797E',
  SACRA_THW_10_ITEM: '0x586f13Ed596B07FB57D5643A64c177eD59Da0aB8',
  SACRA_PARALYZE_ITEM: '0x77B1DB3BE230bA9AA1BF88d2d65EAFD97891d583',
  SACRA_GUIDING_LIGHT_ITEM: '0x2C37D6c08502B5a2235b750cfeb5E2Fe400b5418',
  SACRA_GUT_PUNCH_ITEM: '0x1011a0289b2BA6F0D29BCaaCB56cF780Eb7c19C4',
  SACRA_LAST_DANCE_ITEM: '0xD8fbc841FAE0682CDD4b1b5d7789a07215beE091',
  SACRA_I_SURVIVE_ITEM: '0xa365B73aFffA16DF639f4D62dbFaD0d8e5ca5b04',
  SACRA_ARMOR_OF_FAITH_ITEM: '0xd15563162301EcD765bFB54eBd0809b21932C506',
  SACRA_THUNDERBOLT_ITEM: '0x80655ADe5e019EB8099d165692151Fd732f3C6eE',
  SACRA_CONS_13_ITEM: '0x38a246704f1cF9346A2e85aaad8B874cA1787900',
  SACRA_CONS_14_ITEM: '0x825bc1099e6ca3C21c7feB0D474448A1258649a8',
  SACRA_CONS_15_ITEM: '0xA842cee4E5e4537718B5cA37145f6BdF606014f5',
  SACRA_CONS_16_ITEM: '0x50Dc1281076544a7a8175B0E357f98683C07De08',
  SACRA_CONS_25_ITEM: '0xc32f1D86daa3Dd29b1715e3835817b34B75559B3',
  SACRA_CONS_26_ITEM: '0xD663c3d0C13b61456B49e1b6Eb04AAe0Db4c478b',
  SACRA_HELM_4_ITEM: '0x8828F8ACB11763892A51714cA0cF611B759E7adC',
  SACRA_HELM_5_ITEM: '0xcD8c7aA73226863b054F2906a89c5De2C3621D82',
  SACRA_BODY_4_ITEM: '0xDd9D76394AD0D15Cb43e828e4e3EB1B03C659218',
  SACRA_BODY_5_ITEM: '0xc32DC21829334EF901beA850357f2ab04ba36BD2',
  SACRA_GLOVES_4_ITEM: '0x7aBDDdF58C05db4D667E5A6a2608d8F99fD432dD',
  SACRA_GLOVES_5_ITEM: '0x5C65bdebca760d113B4Ef334013eAFf07779F00b',
  SACRA_BELT_4_ITEM: '0xae7A9c24642D257120F8685a39808785A7785f73',
  SACRA_AMULET_7_ITEM: '0xB564D64014F52fd7Eb1CB7e639C134Ec24C76Ed2',
  SACRA_AMULET_8_ITEM: '0x56087784f94705148329bfCF77A0aA0d22E0EA0C',
  SACRA_RING_7_ITEM: '0x1B8A847b8e46F803AD6750a26C06b8bC1f0032ea',
  SACRA_RING_8_ITEM: '0x368F177BC2aB1e0247294821Be841e389B46F69C',
  SACRA_OFF_7_ITEM: '0xA81505bA06f680De93f3a857f920CDC0b0C9cdD4',
  SACRA_OFF_8_ITEM: '0x9559360E96Bb80C4DC65D7a4E950753C5172C59d',
  SACRA_OFF_9_ITEM: '0x9A261C67baf011cE620F64cADb9FC24533895188',
  SACRA_OFF_12_ITEM: '0x760DA431cc361E826c2A5B82e4f57135e77b446f',
  SACRA_OFF_13_ITEM: '0xA942eE27177F12A2494620821c33dC18FCC3F1F5',
  SACRA_BOOTS_4_ITEM: '0xC9415e8134cc398f542A2D926e814275dfb0DB7A',
  SACRA_BOOTS_7_ITEM: '0x21Fc216A0DEf5A6c5ab481FeFd10837A1cfB1FD3',
  SACRA_OHW_10_ITEM: '0xAC764c4150EdFAfA67EC688F8423Ba12624b448B',
  SACRA_OHW_11_ITEM: '0x3b9AFEBaD9490916aC286EAe9005921eFdfc29a0',
  SACRA_THW_8_ITEM: '0x1451784C3D93F363C80Efbddf2E300c5a620CAf8',
  SACRA_THW_9_ITEM: '0xCd72ec3d469ecFCf37CBB7979d8F916dDdE939cE',
  SACRA_THW_11_ITEM: '0x0F72964Fa4b766521d84107991E837c30D0EF2b1',
  SACRA_REFLECTION_ITEM: '0x0163948cda17ca2a313F00B7F0301BB3Bf98dBb0',
  SACRA_SCARLET_BRANCH_ADEPT_ITEM: '0x178bE46EdE386fcd4066dAB380b8Bb68a313Ab1C',
  SACRA_ANATHEMA_ITEM: '0x0E90bF48b16C5B409Dc33e261EfCa205623fe686',
  SACRA_GIANTS_BLOOD_ITEM: '0x7D0aD4BBFC7957415957F5a055A5Ddf83f812a2a',
  SACRA_BACKSTAB_ITEM: '0xf057DCa23C4E38281616c85e8784f80bA9EBda38',
  SACRA_VETERAN_CONFIDENCE_ITEM: '0x1Be28F67996eaB29270fCfa2D2815d96d94590AB',
  SACRA_LIVE_FORTRESS_ITEM: '0xCa3CBe4e8542235Ed3eBad291724F2c93ed3A9E7',
  SACRA_COLD_SHOULDER_ITEM: '0x7be8dB617386Cf34e7fE50Cf27599C178D57a03f',
  SACRA_ERASE_ITEM: '0x318ecFd6B245Ae618D68e702a51fc3dcaaeac1b9',
};
