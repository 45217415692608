import { Injectable } from '@angular/core';
import {
  ChamberEntity,
  ChamberGQL,
  DailyActivityCompletedDataGQL,
  DailyActivityCompletedDataQuery,
  DungeonEntity,
  DungeonGQL,
  FreeDungeonsGQL,
  GeneralTokenomicsDataGQL,
  GeneralTokenomicsDataQuery,
  GlobalCustomDataEntity,
  GlobalCustomDataGQL,
  GraphDataGQL,
  GraphDataQuery,
  HeroCustomData,
  HeroCustomDataGQL,
  HeroEntity,
  HeroEntity_OrderBy,
  HeroesInAddressDataGQL,
  HeroesMetaByAddressGQL,
  HeroesMetaGQL,
  HeroesRefCodeByAddressGQL,
  HeroesRefCodeGQL,
  HeroesTopListAccountGQL,
  HeroesTopListGQL,
  HeroGQL,
  HeroMetaEntity,
  ItemEntity,
  ItemGQL,
  ItemMetaEntity,
  ItemMetaListGQL, ItemUnionEntity, ItemUnionListGQL,
  LastItemOwnerGQL,
  LootBoxDataGQL,
  LootBoxDataQuery,
  LootBoxOpenedDataGQL,
  LootBoxOpenedDataQuery,
  OpenedChamberGQL,
  OpenedChamberQuery,
  OrderDirection,
  PawnshopEntity,
  PawnshopGQL,
  PawnshopHistoryGQL,
  PawnshopPositionEntity,
  PawnshopPositionFloorGQL,
  PawnshopPositionHistoryEntity,
  PawnshopPositionsGQL,
  StoryEntity,
  StoryGQL,
  TokenEntity,
  TokenGQL,
  TokenTransactionEntity,
  TokenTransactionsFromGQL,
  TokenTransactionsToGQL,
  TransactionsGtTimestapDataGQL,
  UserGQL,
  UserItemsGQL,
  UserLastActionGQL,
  UserQuery,
  UserStatGQL,
  UserStatQuery,
} from '@generated/gql';
import { DestroyService } from '@services/destroy.service';
import { ethers } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { expand, filter, map, Observable, retry, tap } from 'rxjs';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { reduce } from 'rxjs/internal/operators/reduce';

const RETRY = 10;
const DELAY = 1_000;

@Injectable({
  providedIn: 'root',
})
export class SubgraphService {
  // public lastSubgraphData = new Subject<GraphDataQuery['_meta']>();
  public currentSubgraphBlock?: number;
  public currentSubgraphDelaySec?: number;

  constructor(
    private userGQL: UserGQL,
    private userLastActionGQL: UserLastActionGQL,
    private tokenGQL: TokenGQL,
    private itemGQL: ItemGQL,
    private chamberGQL: ChamberGQL,
    private dungeonGQL: DungeonGQL,
    private freeDungeonsGQL: FreeDungeonsGQL,
    private heroGQL: HeroGQL,
    private heroesMetaGQL: HeroesMetaGQL,
    private heroesTopListGQL: HeroesTopListGQL,
    private pawnshopGQL: PawnshopGQL,
    private openedChamberGQL: OpenedChamberGQL,
    private pawnshopPositionsGQL: PawnshopPositionsGQL,
    private userItemsGQL: UserItemsGQL,
    private graphDataGQL: GraphDataGQL,
    private heroMetaByAddressGQL: HeroesMetaByAddressGQL,
    private heroCustomDataGQL: HeroCustomDataGQL,
    private globalCustomDataGQL: GlobalCustomDataGQL,
    private generalTokenomicsDataGQL: GeneralTokenomicsDataGQL,
    private heroesTopListAccountGQL: HeroesTopListAccountGQL,
    private userStatGQL: UserStatGQL,
    private heroesRefCodeGQL: HeroesRefCodeGQL,
    private heroesRefCodeByAddressGQL: HeroesRefCodeByAddressGQL,
    private dailyActivityCompletedGQL: DailyActivityCompletedDataGQL,
    private lootBoxGQL: LootBoxDataGQL,
    private lootBoxOpenedGQL: LootBoxOpenedDataGQL,
    private pawnshopHistoryGQL: PawnshopHistoryGQL,
    private itemMetaListGQL: ItemMetaListGQL,
    private lastItemOwnerGQL: LastItemOwnerGQL,
    private pawnshopPositionFloorGQL: PawnshopPositionFloorGQL,
    private storyGQL: StoryGQL,
    private heroesInAddressDataGQL: HeroesInAddressDataGQL,
    private transactionGtTimestampData: TransactionsGtTimestapDataGQL,
    private tokenTransactionFromGQL: TokenTransactionsFromGQL,
    private tokenTransactionsToGQL: TokenTransactionsToGQL,
    private itemUnionListGQL: ItemUnionListGQL,
    private logger: NGXLogger,
    private destroy$: DestroyService,
  ) {
    // timer(0, 5_000)
    //   .pipe(
    //     switchMap(() => this.graphData$()),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(data => {
    //     if (data) {
    //       if (data.hasIndexingErrors) {
    //         this.logger.error('Subgraph has indexing errors!');
    //       } else {
    //         this.lastSubgraphData.next(data);
    //         this.currentSubgraphBlock = data.block.number;
    //         this.currentSubgraphDelaySec = Math.floor(Date.now() / 1000 - (data.block.timestamp ?? 0));
    //         // this.logger.trace('Current subgraph block', this.currentSubgraphBlock, this.currentSubgraphDelaySec);
    //       }
    //     }
    //   });
  }

  graphData$(): Observable<GraphDataQuery['_meta']> {
    return this.graphDataGQL.fetch().pipe(
      map(x => x.data._meta),
      tap(data => {
        if (data) {
          this.currentSubgraphBlock = data.block.number;
          this.currentSubgraphDelaySec = Math.floor(Date.now() / 1000 - (data.block.timestamp ?? 0));
        }
      }),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  user$(account: string): Observable<UserQuery['userEntity']> {
    return this.userGQL.fetch({ account: account.toLowerCase() }).pipe(
      map(x => x.data.userEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  userLastActionBlock$(account: string): Observable<number> {
    return this.userLastActionGQL.fetch({ account: account.toLowerCase() }).pipe(
      map(x => x.data.userEntity?.lastActionBlock ?? 0),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  dailyActivityCompleted$(
    account: string,
    first: number = 7,
  ): Observable<DailyActivityCompletedDataQuery['dailyActivityCompletedEntities']> {
    return this.dailyActivityCompletedGQL.fetch({ user: account.toLowerCase(), first: first }).pipe(
      map(x => x.data.dailyActivityCompletedEntities),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  lastLootBoxOpened$(
    account: string,
    lootBoxKind: string,
  ): Observable<LootBoxOpenedDataQuery['lootBoxOpenedHistoryEntities']> {
    return this.lootBoxOpenedGQL.fetch({ user: account.toLowerCase(), lootBoxKind: lootBoxKind }).pipe(
      map(x => x.data.lootBoxOpenedHistoryEntities),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  lootBox$(): Observable<LootBoxDataQuery['lootBoxEntities']> {
    return this.lootBoxGQL.fetch().pipe(
      map(x => x.data.lootBoxEntities),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  userStat$(account: string): Observable<UserStatQuery['userStatEntity']> {
    return this.userStatGQL.fetch({ account: account.toLowerCase() }).pipe(
      map(x => x.data.userStatEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  token$(tokenAdr: string): Observable<TokenEntity> {
    return this.tokenGQL.fetch({ tokenAdr: tokenAdr.toLowerCase() }).pipe(
      map(x => x.data.tokenEntity as TokenEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  generalTokenomics$(): Observable<GeneralTokenomicsDataQuery['generalTokenomicsEntities']> {
    return this.generalTokenomicsDataGQL.fetch().pipe(
      map(x => x.data.generalTokenomicsEntities),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  openedChamber$(
    dungAdr: string,
    chamberAdr: string,
    heroAdr: string,
    heroId: number,
    stage: number,
    iteration: number,
  ): Observable<OpenedChamberQuery['openedChamberEntity']> {
    this.logger.trace('openedChamber$', dungAdr, chamberAdr, heroAdr, heroId, stage, iteration);
    const chamberId = ethers.keccak256(
      ethers.toUtf8Bytes(
        dungAdr.toLowerCase() + chamberAdr.toLowerCase() + heroAdr.toLowerCase() + heroId + stage + iteration,
      ),
    );
    return this.openedChamberGQL.fetch({ chamberId: chamberId }).pipe(
      map(x => x.data.openedChamberEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  item$(itemAdr: string, itemId: number): Observable<ItemEntity> {
    return this.itemGQL.fetch({ itemGraphId: itemAdr.toLowerCase() + '-' + itemId.toString() }).pipe(
      map(x => x.data.itemEntity as ItemEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  hero$(heroAdr: string, heroId: number): Observable<HeroEntity> {
    return this.heroGQL.fetch({ heroGraphId: heroAdr.toLowerCase() + '-' + heroId.toString() }).pipe(
      map(x => x.data.heroEntity as HeroEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  lastItemOwner$(metaId: string, user: string): Observable<ItemEntity[]> {
    return this.lastItemOwnerGQL.fetch({ metaId, user }).pipe(
      map(x => (x?.data?.itemEntities ?? []) as ItemEntity[]),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  pawnshopPositionFloor$(itemAdr: string): Observable<number> {
    return this.pawnshopPositionFloorGQL.fetch({ itemAdr: itemAdr.toLowerCase() }).pipe(
      map(x => (x?.data?.pawnshopPositionEntities ?? []) as PawnshopPositionEntity[]),
      map(x => {
        if (x && x.length !== 0) {
          return Number(x[0].acquiredAmount);
        } else {
          return 0;
        }
      }),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  story$(storyId: string): Observable<StoryEntity> {
    return this.storyGQL.fetch({ storyId }).pipe(
      map(x => x.data.storyEntity as StoryEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroesTopList$(chunk = 1000): Observable<HeroEntity[]> {
    let skip = 0;
    return this.heroesTopListPaginated$(chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.heroesTopListPaginated$(chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as HeroEntity[]),
    );
  }

  heroesTopListPaginated$(
    first: number,
    skip: number,
    heroClass: number[] = [],
    orderBy: HeroEntity_OrderBy = HeroEntity_OrderBy.Score,
    orderDirection: OrderDirection = OrderDirection.Desc,
  ): Observable<HeroEntity[]> {
    return this.heroesTopListGQL.fetch({ first: first, skip: skip, heroClass, orderBy, orderDirection }).pipe(
      map(x => x?.data?.heroEntities ?? []),
      map(e => e.map(el => el as HeroEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroesTopListAccountPaginated$(
    first: number,
    skip: number,
    user: string,
    heroClass: number[] = [],
    orderBy: HeroEntity_OrderBy = HeroEntity_OrderBy.Score,
    orderDirection: OrderDirection = OrderDirection.Desc,
  ): Observable<HeroEntity[]> {
    return this.heroesTopListAccountGQL
      .fetch({ first: first, skip: skip, user: user.toLowerCase(), heroClass, orderBy, orderDirection })
      .pipe(
        map(x => x?.data?.heroEntities ?? []),
        map(e => e.map(el => el as HeroEntity)),
        retry({ count: RETRY, delay: DELAY }),
      );
  }

  heroesMeta$(): Observable<HeroMetaEntity[]> {
    return this.heroesMetaGQL.fetch().pipe(
      map(x => (x?.data?.heroMetaEntities ?? []).map(e => e as HeroMetaEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroMetaByAddress$(heroAdr: string): Observable<HeroMetaEntity[]> {
    return this.heroMetaByAddressGQL
      .fetch({
        heroAdr,
      })
      .pipe(
        map(x => x.data.heroMetaEntities),
        filter(e => e.length !== 0),
        map(e => e.map(el => el as HeroMetaEntity)),
        retry({ count: RETRY, delay: DELAY }),
      );
  }

  chamber$(chamberAdr: string): Observable<ChamberEntity> {
    return this.chamberGQL.fetch({ chamber: chamberAdr.toLowerCase() }).pipe(
      map(x => x.data.chamberEntity),
      map(e => e as ChamberEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  dungeon$(dungeonAdr: string): Observable<DungeonEntity> {
    return this.dungeonGQL.fetch({ dungeonAdr: dungeonAdr.toLowerCase() }).pipe(
      map(x => x.data.dungeonEntity),
      retry({ count: RETRY, delay: DELAY }),
      map(x => x as DungeonEntity),
    );
  }

  freeDungeons$(biome: number): Observable<DungeonEntity[]> {
    return this.freeDungeonsGQL.fetch({ biome: biome }).pipe(
      map(x => x?.data?.dungeonEntities ?? []),
      retry({ count: RETRY, delay: DELAY }),
      map(x => x.map(d => d as DungeonEntity)),
    );
  }

  usersItems$(userAddress: string, chunk = 1000): Observable<ItemEntity[]> {
    let skip = 0;
    return this.userItemsPaginated$(chunk, 0, userAddress.toLowerCase()).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.userItemsPaginated$(chunk, skip, userAddress.toLowerCase());
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as ItemEntity[]),
      map(r =>
        r.sort((a, b) => {
          if (a.meta.itemType < b.meta.itemType) return 1;
          if (a.meta.itemType > b.meta.itemType) return -1;
          if (a.meta.level < b.meta.level) return 1;
          if (a.meta.level > b.meta.level) return -1;
          if (a.meta.id < b.meta.id) return -1;
          if (a.meta.id > b.meta.id) return 1;
          if (a.itemId < b.itemId) return 1;
          if (a.itemId > b.itemId) return -1;
          return 0;
        }),
      ),
    );
  }

  allItemMetas$(chunk = 1000): Observable<ItemMetaEntity[]> {
    let skip = 0;
    return this.itemMetaList$(chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.itemMetaList$(chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as ItemMetaEntity[]),
    );
  }

  allItemUnion$(chunk = 1000): Observable<ItemUnionEntity[]> {
    let skip = 0;
    return this.itemUnionList$(chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.itemUnionList$(chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as ItemUnionEntity[]),
    );
  }

  allHeroesInIds$(ids: string[], chunk = 1000): Observable<HeroEntity[]> {
    let skip = 0;
    return this.heroesInIds$(ids, chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.heroesInIds$(ids, chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as HeroEntity[]),
    );
  }

  allTransactionGtTimestamp$(
    from: string,
    to: string,
    timestamp: string,
    chunk = 1000,
  ): Observable<TokenTransactionEntity[]> {
    let skip = 0;
    return this.transactionGtTimestamp$(from, to, timestamp, chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.transactionGtTimestamp$(from, to, timestamp, chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as TokenTransactionEntity[]),
    );
  }

  heroesRefCode$(chunk = 1000): Observable<HeroEntity[]> {
    let skip = 0;
    return this.heroesRefCodePaginated$(chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.heroesRefCodePaginated$(chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as HeroEntity[]),
    );
  }

  heroesRefCodeByAddress$(address: string, chunk = 1000): Observable<HeroEntity[]> {
    let skip = 0;
    return this.heroesRefCodeByAddressPaginated$(chunk, 0, address).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.heroesRefCodeByAddressPaginated$(chunk, skip, address);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as HeroEntity[]),
    );
  }

  userItemsPaginated$(first: number, skip: number, userAddress: string): Observable<ItemEntity[]> {
    return this.userItemsGQL.fetch({ first: first, skip: skip, userAddress: userAddress }).pipe(
      map(x => x?.data?.itemEntities ?? []),
      map(e => e.map(el => el as ItemEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  itemMetaList$(first: number, skip: number): Observable<ItemMetaEntity[]> {
    return this.itemMetaListGQL.fetch({ first: first, skip: skip }).pipe(
      map(x => x?.data?.itemMetaEntities ?? []),
      map(e => e.map(el => el as ItemMetaEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  itemUnionList$(first: number, skip: number): Observable<ItemUnionEntity[]> {
    return this.itemUnionListGQL.fetch({ first: first, skip: skip }).pipe(
      map(x => x?.data?.itemUnionEntities ?? []),
      map(e => e.map(el => el as ItemUnionEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroesInIds$(ids: string[], first: number, skip: number): Observable<HeroEntity[]> {
    return this.heroesInAddressDataGQL.fetch({ ids: ids, first: first, skip: skip }).pipe(
      map(x => x?.data?.heroEntities ?? []),
      map(e => e.map(el => el as HeroEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  transactionGtTimestamp$(
    from: string,
    to: string,
    timestamp: string,
    first: number,
    skip: number,
  ): Observable<TokenTransactionEntity[]> {
    return this.transactionGtTimestampData
      .fetch({ from: from, to: to, timestamp: timestamp, first: first, skip: skip })
      .pipe(
        map(x => x?.data?.tokenTransactionEntities ?? []),
        map(e => e.map(el => el as TokenTransactionEntity)),
        retry({ count: RETRY, delay: DELAY }),
      );
  }

  heroesRefCodePaginated$(first: number, skip: number): Observable<HeroEntity[]> {
    return this.heroesRefCodeGQL.fetch({ first: first, skip: skip }).pipe(
      map(x => x?.data?.heroEntities ?? []),
      map(e => e.map(el => el as HeroEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroesRefCodeByAddressPaginated$(first: number, skip: number, address: string): Observable<HeroEntity[]> {
    return this.heroesRefCodeByAddressGQL.fetch({ first: first, skip: skip, address }).pipe(
      map(x => x?.data?.heroEntities ?? []),
      map(e => e.map(el => el as HeroEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  pawnshop$(): Observable<PawnshopEntity> {
    return this.pawnshopGQL.fetch().pipe(
      map(x => x?.data?.pawnshopEntities ?? []),
      map(e => e[0] as PawnshopEntity),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  pawnshopPositions$(chunk = 1000): Observable<PawnshopPositionEntity[]> {
    let skip = 0;
    return this.pawnshopPositionsPaginated$(chunk, 0).pipe(
      expand(pos => {
        if (pos.length !== 0) {
          skip += chunk;
          return this.pawnshopPositionsPaginated$(chunk, skip);
        }
        return EMPTY;
      }),
      reduce((acc, value) => acc.concat(value), [] as PawnshopPositionEntity[]),
    );
  }

  pawnshopPositionsPaginated$(first: number, skip: number): Observable<PawnshopPositionEntity[]> {
    return this.pawnshopPositionsGQL.fetch({ first: first, skip: skip }).pipe(
      map(x => x?.data?.pawnshopPositionEntities ?? []),
      map(e => e.map(el => el as PawnshopPositionEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  pawnshopHistoryPaginated$(first: number, skip: number, user: string): Observable<PawnshopPositionHistoryEntity[]> {
    return this.pawnshopHistoryGQL.fetch({ first: first, skip: skip, userAdr: user.toLowerCase() }).pipe(
      map(x => x?.data?.pawnshopPositionHistoryEntities ?? []),
      map(e => e.map(el => el as PawnshopPositionHistoryEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  heroCustomData$(dataIndexes: string[], heroAdr: string, heroId: string): Observable<HeroCustomData[]> {
    return this.heroCustomDataGQL
      .fetch({
        dataIndexes: dataIndexes,
        // convert to id heroAdr-heroId
        heroId: `${heroAdr}-${heroId}`,
      })
      .pipe(
        map(x => x.data.heroCustomDatas),
        map(e => e.map(el => el as HeroCustomData)),
        retry({ count: RETRY, delay: DELAY }),
      );
  }

  globalCustomData$(ids: string[]): Observable<GlobalCustomDataEntity[]> {
    return this.globalCustomDataGQL
      .fetch({
        ids: ids,
      })
      .pipe(
        map(x => x.data.globalCustomDataEntities),
        map(e => e.map(el => el as GlobalCustomDataEntity)),
        retry({ count: RETRY, delay: DELAY }),
      );
  }

  tokenTransactionsFrom$(
    from: string,
    token: string,
    first: number = 10,
    skip: number = 0,
  ): Observable<TokenTransactionEntity[]> {
    return this.tokenTransactionFromGQL.fetch({ from: from, tokenAdr: token, first: first, skip: skip }).pipe(
      map(x => x.data.tokenTransactionEntities),
      map(e => e.map(el => el as TokenTransactionEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  tokenTransactionsTo$(
    to: string,
    token: string,
    first: number = 10,
    skip: number = 0,
  ): Observable<TokenTransactionEntity[]> {
    return this.tokenTransactionsToGQL.fetch({ to: to, tokenAdr: token, first: first, skip: skip }).pipe(
      map(x => x.data.tokenTransactionEntities),
      map(e => e.map(el => el as TokenTransactionEntity)),
      retry({ count: RETRY, delay: DELAY }),
    );
  }

  lastTokenTransactions(user: string, token: string): Observable<TokenTransactionEntity[]> {
    return forkJoin([this.tokenTransactionsFrom$(user, token), this.tokenTransactionsTo$(user, token)]).pipe(
      map(([from, to]) => {
        const allTransactions = from.concat(to);
        const sortedTransactions = allTransactions.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
        return sortedTransactions.slice(0, 10);
      }),
    );
  }
}
