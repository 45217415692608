/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  HeroController,
  HeroControllerInterface,
} from "../HeroController";

const _abi = [
  {
    inputs: [],
    name: "AlreadyHaveReinforcement",
    type: "error",
  },
  {
    inputs: [],
    name: "EquippedItemsExist",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorForbidden",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "ErrorHeroIsDead",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
    ],
    name: "ErrorHeroIsNotRegistered",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorHeroNotInDungeon",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
    ],
    name: "ErrorIncorrectBiome",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDungeonFactory",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotItemController",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ErrorNotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "HeroInDungeon",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NameTaken",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "payTokenAmount",
        type: "uint256",
      },
    ],
    name: "NoPayToken",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "NotEOA",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "Staked",
    type: "error",
  },
  {
    inputs: [],
    name: "TooBigName",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
    ],
    name: "TooHighBiome",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongSymbolsInTheName",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "BiomeChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "helpHeroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "helpHeroId",
        type: "uint256",
      },
    ],
    name: "GuildReinforcementAsked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "helperToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "GuildReinforcementReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "refCode",
        type: "string",
      },
    ],
    name: "HeroCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "heroClass",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "payToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "payAmount",
        type: "uint256",
      },
    ],
    name: "HeroRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "killer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "dropItems",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "dropTokenAmount",
        type: "uint256",
      },
    ],
    name: "Killed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        indexed: false,
        internalType: "struct IStatController.CoreAttributes",
        name: "change",
        type: "tuple",
      },
    ],
    name: "LevelUp",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "helpHeroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "helpHeroId",
        type: "uint256",
      },
    ],
    name: "ReinforcementAsked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "helperToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "ReinforcementReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "askGuildReinforcement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "askReinforcement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "beforeTokenTransfer",
    outputs: [
      {
        internalType: "bool",
        name: "isAllowedToTransferOut",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "countHeroTransfers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "string",
        name: "heroName_",
        type: "string",
      },
      {
        internalType: "bool",
        name: "enter",
        type: "bool",
      },
    ],
    name: "create",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "string",
        name: "heroName_",
        type: "string",
      },
      {
        internalType: "string",
        name: "refCode",
        type: "string",
      },
      {
        internalType: "bool",
        name: "enter",
        type: "bool",
      },
    ],
    name: "createWithRefCode",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "heroBiome",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
    ],
    name: "heroClass",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "heroName",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "heroReinforcementHelp",
    outputs: [
      {
        internalType: "address",
        name: "helperHeroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperHeroId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "isAllowedToTransfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "kill",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "dropItems",
        type: "bytes32[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        internalType: "struct IStatController.CoreAttributes",
        name: "change",
        type: "tuple",
      },
    ],
    name: "levelUp",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "nameToHero",
    outputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
    ],
    name: "payTokenInfo",
    outputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "heroClass_",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "payToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "payAmount",
        type: "uint256",
      },
    ],
    name: "registerHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "releaseReinforcement",
    outputs: [
      {
        internalType: "address",
        name: "helperToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "resetLifeAndMana",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "score",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "setBiome",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "decLifeChances",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "resetMana",
        type: "bool",
      },
    ],
    name: "softKill",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "dropItems",
        type: "bytes32[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class HeroController__factory {
  static readonly abi = _abi;
  static createInterface(): HeroControllerInterface {
    return new Interface(_abi) as HeroControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): HeroController {
    return new Contract(address, _abi, runner) as unknown as HeroController;
  }
}
