export enum HEROES {
  UNKNOWN,
  THRALL,
  SAVAGE,
  MAGE,
  ASSASSIN,
  GHOST,
  HAMMERGINA,
}

export const HEROES_CLASSES_ICONS = {
  [HEROES.UNKNOWN]: 'unknown',
  [HEROES.THRALL]: 'thrall',
  [HEROES.SAVAGE]: 'savage',
  [HEROES.MAGE]: 'mage',
  [HEROES.ASSASSIN]: 'assassin',
  [HEROES.GHOST]: 'ghost',
  [HEROES.HAMMERGINA]: 'hammergina',
};

export const HEROES_CLASSES_ID = {
  [HEROES.UNKNOWN]: 'unknown',
  [HEROES.THRALL]: 'thrall',
  [HEROES.SAVAGE]: 'savage',
  [HEROES.MAGE]: 'mage',
  [HEROES.ASSASSIN]: 'assassin',
  [HEROES.GHOST]: 'ghost',
  [HEROES.HAMMERGINA]: 'hammergina',
};

export const HEROES_CLASSES_NAMES = {
  [HEROES.UNKNOWN]: 'heroes-classes.unknown',
  [HEROES.THRALL]: 'heroes-classes.thrall',
  [HEROES.SAVAGE]: 'heroes-classes.savage',
  [HEROES.MAGE]: 'heroes-classes.mage',
  [HEROES.ASSASSIN]: 'heroes-classes.assassin',
  [HEROES.GHOST]: 'heroes-classes.ghost',
  [HEROES.HAMMERGINA]: 'heroes-classes.hammergina',
};

export interface HeroesSpecs {
  damage: number;
  survival: number;
  complex: number;
}

export const HEROES_SPECS: { [key: string]: HeroesSpecs } = {
  [HEROES.UNKNOWN]: { damage: 0, survival: 0, complex: 0 },
  [HEROES.THRALL]: { damage: 0.3, survival: 0.8, complex: 0.3 },
  [HEROES.SAVAGE]: { damage: 0.8, survival: 0.3, complex: 0.4 },
  [HEROES.MAGE]: { damage: 0.9, survival: 0.2, complex: 1 },
  [HEROES.ASSASSIN]: { damage: 0.4, survival: 0.5, complex: 0.6 },
  [HEROES.GHOST]: { damage: 1, survival: 1, complex: 0 },
  [HEROES.HAMMERGINA]: { damage: 0.8, survival: 0.9, complex: 0.2 },
};

export const HEROES_CLASSES_NAMES_MAP = new Map<HEROES, string>([
  [HEROES.UNKNOWN, 'heroes-classes.all'],
  [HEROES.THRALL, 'heroes-classes.thrall'],
  [HEROES.SAVAGE, 'heroes-classes.savage'],
  [HEROES.MAGE, 'heroes-classes.mage'],
  [HEROES.ASSASSIN, 'heroes-classes.assassin'],
  [HEROES.GHOST, 'heroes-classes.ghost'],
  [HEROES.HAMMERGINA, 'heroes-classes.hammergina'],
]);

export const getAvatarByHeroClass = (heroClass: number) => {
  const heroClassName = HEROES_CLASSES_ICONS[heroClass];

  return `assets/images/ui/avatars/heroes/${heroClassName.toLowerCase()}.avif`;
};

export const HEROES_CLASSES_SHORT_DESC_MAP = new Map<HEROES, string>([
  [HEROES.THRALL, 'heroes-classes-short-description.thrall'],
  [HEROES.SAVAGE, 'heroes-classes-short-description.savage'],
  [HEROES.MAGE, 'heroes-classes-short-description.mage'],
  [HEROES.ASSASSIN, 'heroes-classes-short-description.assassin'],
  [HEROES.GHOST, 'heroes-classes-short-description.ghost'],
  [HEROES.HAMMERGINA, 'heroes-classes-short-description.hammergina'],
]);
